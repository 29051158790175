<!--
 * @Author: hwu
 * @Date: 2020-11-17 09:43:05
 * @Description: 菜谱页面 -> 购物车详情
 * @LastEditTime: 2024-04-10 16:06:05
-->

<template>
  <div class="wh-popup-wraper" @touchmove.stop>
    <transition name="wh-popup">
      <div class="wh-popup-box" v-if="show">
        <menu-cart-detail-content isInPopup @onClose="onClose" />
      </div>
    </transition>
    <transition name="wh-backdrop">
      <div class="wh-popup-backdrop" @click="onClose" v-if="show"></div>
    </transition>
  </div>
</template>
<script>
import MenuCartDetailContent from './MenuCartDetailContent.vue'
export default {
  name: 'menu-cart-detail-popup',
  components: { MenuCartDetailContent },
  props: {
    show: { type: Boolean, required: true, default: false }
  },
  data() {
    return {}
  },
  methods: {
    onClose(e) {
      this.$emit('onClose', e)
    }
  }
}
</script>
<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  max-height: 80vh;
  background-color: $color-white;
  border-radius: 32px 32px 0 0;
  overflow: hidden;
  z-index: 99;
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background: #000;
  z-index: 98;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateY(100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}
</style>
