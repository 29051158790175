<!--
 * @Author: hwu
 * @Date: 2020-11-17 09:43:05
 * @Description: 菜谱页面 -> 购物车详情
 * @LastEditTime: 2024-04-10 15:03:49
-->

<template>
  <div class="cart-box">
    <div class="cart-header">
      <div class="cart-header-title">已选商品</div>
      <div class="cart-header-btn" @click="clearDialogShow = true" v-if="cartItems && cartItems.length > 0">
        <i class="iconfont iconqingkong"></i>
        <span>清空</span>
      </div>
    </div>
    <div class="cart-body" :class="{ 'cart-empty': !cartItems || cartItems.length === 0 }">
      <div class="cart-empty-box" v-if="!cartItems || cartItems.length === 0">暂无已加购的商品~</div>

      <div class="cart-item-box">
        <div class="category-box" v-for="(category, index) in cartItems" :key="index">
          <div class="category-title">{{ category.categoryName }}</div>
          <div class="single-item-box" v-for="(item, itemIndex) in category.items" :key="itemIndex" v-show="item.quantity > 0">
            <div class="single-item-right">
              <div class="item-info">
                <div class="item-info_name">{{ item.itemName }}</div>
                <div class="item-info_price">{{ item.price | priceFilter }}</div>
                <div class="item-info_btn">
                  <span class="item-btn minus-btn" :class="{ disabled: item.promoType === 2 }" @click="minusItemQty(item)">
                    <i class="iconfont iconjianshao"></i>
                  </span>
                  <template v-if="item.weighingStatus === 1 && item.ownTakeStatus === 0 && !item.maxWeight">
                    <span class="item-qty">{{ item.weight }}{{ item.unitName }}</span>
                  </template>
                  <template v-else>
                    <span class="item-qty">{{ item.quantity }}</span>
                  </template>
                  <span class="item-btn add-btn" :class="{ disabled: item.promoType === 2 || (item.weighingStatus === 1 && item.ownTakeStatus === 1) }" @click="addItemQty(item)">
                    <i class="iconfont iconzengjia-tianchong"></i>
                  </span>
                </div>
              </div>
              <div class="item-desc" v-if="item.spec || item.make || item.taste || item.promoTag">
                <span class="item-desc_text" v-if="item.spec">{{ item.spec }}</span>
                <span class="item-desc_text" v-if="item.make">{{ item.make }}</span>
                <span class="item-desc_text" v-if="item.taste">{{ item.taste }}</span>
                <span class="item-desc_tag" v-if="item.promoTag && item.discountAmount > 0">{{ item.promoTag }}，已优惠{{ item.discountAmount }}元</span>
              </div>
              <div class="detail-selected" :class="{ active: detailShowItemKey === item.uniKey }" v-if="(item.subCharges && item.subCharges.length > 0) || (item.subItems && item.subItems.length > 0)">
                <div class="detail-selected-box" @click="toggleCartItemDetail(item.uniKey)">
                  <div class="detail-selected-content">
                    <div class="result-content">
                      <span class="result-item" v-for="(sub, subIndex) in item.subItems" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                      <span class="result-item" v-for="(sub, subIndex) in item.subCharges" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                    </div>
                    <div class="collapse-btn">
                      <i class="iconfont iconfangxiang-xiangxia"></i>
                    </div>
                  </div>
                </div>
                <div class="detail-selected-box_fixed" @click="toggleCartItemDetail(item.uniKey)">
                  <div class="detail-selected-content">
                    <div class="result-content">
                      <span class="result-item" v-for="(sub, subIndex) in item.subItems" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                      <span class="result-item" v-for="(sub, subIndex) in item.subCharges" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                    </div>
                    <span class="collapse-btn">
                      <i class="iconfont iconfangxiang-xiangshang"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-promo-box" v-if="freePromos && freePromos.length > 0">
        <div class="single-promo-box" v-for="(promo, index) in freePromos" :key="index">
          <div class="promo-name">{{ promo.promoName }}</div>
          <div class="promo-btn">
            <span class="btn-item" @click="showFreePromoPopup()">{{ promo.selected === 1 ? '重选' : '请选择' }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 清空购物车弹窗 -->
    <dialog-box title="温馨提示" content="确认清空购物车？" :show="clearDialogShow" :showLeftBtn="true" leftBtn="取消" rightBtnText="清空" @onLeftClick="clearDialogShow = false" @onRightClick="clearCart"></dialog-box>
    <!-- 赠品选择弹窗 -->
    <menu-cart-free-promo-popup :show="freePromoPopupShow" :isInPopup="isInPopup" @onClose="closeFreePromoPopup()" @onSubmit="closeFreePromoPopup()"></menu-cart-free-promo-popup>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import DialogBox from '@/components/common/DialogBox'
import MenuCartFreePromoPopup from './MenuCartFreePromoPopup.vue'
import { clearCartApi, minusCartItemApi, addCartItemApi } from '@/api/cart'
export default {
  name: 'menu-cart-detail-content',
  components: { DialogBox, MenuCartFreePromoPopup },
  props: {
    isInPopup: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      clearDialogShow: false,
      detailShowItemKey: '',
      freePromoPopupShow: false
    }
  },
  filters: {
    priceFilter(val) {
      if (val === 0) {
        return '免费'
      }
      return '￥' + val
    },
    cartSubItemFilter(val) {
      if (!val.itemName) {
        return ''
      }
      let descList = []
      if (val.spec) {
        descList.push(val.spec)
      }
      if (val.make) {
        descList.push(val.make)
      }
      if (val.taste) {
        descList.push(val.taste)
      }
      let descStr = ''
      if (descList.length > 0) {
        descStr = `(${descList.join(' ')})`
      }
      return `· ${val.itemName}${descStr} x ${val.quantity}`
    }
  },
  computed: {
    ...mapState('order', ['cartId', 'cartItems', 'freePromos', 'userId'])
  },
  methods: {
    ...mapActions('order', ['updateCartInfoVuex']),
    toggleCartItemDetail(key) {
      if (this.detailShowItemKey === key) {
        this.detailShowItemKey = ''
        return
      }
      this.detailShowItemKey = key
    },
    clearCart() {
      clearCartApi(this.cartId, this.userId).then((res) => {
        this.clearDialogShow = false
        const cartInfo = {
          quantity: 0,
          amount: 0,
          categoryList: [],
          itemQuantity: 0
        }
        this.updateCartInfoVuex(cartInfo)
        this.$emit('onClose')
      })
    },
    minusItemQty(item) {
      // 赠品不可以删除
      if (item.promoType === 2) {
        return false
      }
      minusCartItemApi(this.cartId, item.uniKey, this.userId).then((res) => {
        if (res.status !== 0) {
          this.$toast(res.msg)
          return false
        }
        const cartInfo = res.data
        const cartItems = res.data.categoryList
        this.updateCartInfoVuex(cartInfo)
        if (!cartItems || cartItems.length === 0) {
          this.clearDialogShow = false
          this.$emit('onClose')
        }
      })
    },
    addItemQty(item) {
      // 赠品不可以添加
      if (item.promoType === 2) {
        return false
      }
      // 称重商品不可以添加（称重非自取版本：并且ownTakeStatus为0，才可以增加）
      if (item.weighingStatus === 1 && item.ownTakeStatus === 1) {
        return false
      }
      addCartItemApi(this.cartId, item.uniKey, this.userId).then((res) => {
        if (res.status !== 0) {
          this.$toast(res.msg)
          return false
        }
        const cartInfo = res.data
        this.updateCartInfoVuex(cartInfo)
      })
    },
    showFreePromoPopup() {
      this.freePromoPopupShow = true
    },
    closeFreePromoPopup() {
      this.freePromoPopupShow = false
      // this.$emit('onClose')
    }
  }
}
</script>
<style lang="scss" scoped>
.cart-box {
  position: relative;
  width: 100%;
  .cart-header {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    padding: 24px;
    line-height: 48px;
    flex-wrap: nowrap;
    align-items: center;
    background-color: $color-white;
    border-radius: 32px 32px 0 0;
    z-index: 10;
    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px;
      right: 24px;
      height: 1px;
      border-bottom: 1px solid $color-border;
    }
    .cart-header-title {
      flex: 1;
      font-size: 28px;
      font-weight: bold;
      color: $color-text-main;
    }
    .cart-header-btn {
      flex: 0;
      font-size: 26px;
      font-weight: normal;
      white-space: nowrap;
      color: $color-primary;
      i {
        font-size: 32px;
        vertical-align: bottom;
      }
    }
  }
  .cart-body {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 100vh;
    padding: 96px 0 100px;
    overflow-y: auto;
    background-color: $color-background;
    // -webkit-overflow-scrolling: touch;
    &.cart-empty {
      min-height: 50vh;
      padding-bottom: 0;

      .cart-empty-box {
        position: absolute;
        width: 100%;
        left: 0;
        top: 80%;
        text-align: center;
        z-index: 10;
      }
    }
    .cart-item-box {
      width: 100%;
      background-color: $color-white;
      .category-box {
        width: 100%;
        margin-bottom: 24px;
        .category-title {
          position: relative;
          width: 100%;
          padding: 16px 24px 16px 36px;
          line-height: 32px;
          font-size: 26px;
          font-weight: bold;
          color: $color-warning;
          &::before {
            content: '';
            position: absolute;
            left: 24px;
            top: 50%;
            margin-top: -12px;
            width: 4px;
            height: 24px;
            background: $color-warning;
            border-radius: 2px;
            z-index: 1;
          }
        }
        .single-item-box {
          display: flex;
          width: 100%;
          padding: 24px;
          align-items: center;
          .single-item-left {
            flex: 0 0 56px;
            margin-right: 16px;
            font-size: 0;
            img {
              width: 56px;
              height: 56px;
              border-radius: 50%;
            }
          }
          .single-item-right {
            flex: 1;
            .item-info {
              display: flex;
              align-items: center;
              .item-info_name {
                flex: 1;
                font-size: 28px;
                color: $color-text-main;
              }
              .item-info_price {
                flex: 0;
                margin-right: 56px;
                font-size: 28px;
                color: $color-text-main;
                white-space: nowrap;
              }
              .item-info_btn {
                flex: 0;
                display: flex;
                align-items: center;
                .item-btn {
                  font-size: 0;
                  i {
                    color: $color-primary;
                    font-size: 40px;
                  }
                  &.disabled {
                    opacity: 0.5;
                  }
                }
                .item-qty {
                  display: inline-block;
                  min-width: 40px;
                  white-space: nowrap;
                  font-size: 26px;
                  font-weight: bold;
                  text-align: center;
                }
              }
            }
            .item-desc {
              width: 100%;
              margin-top: 8px;
              .item-desc_text {
                margin-right: 10px;
                line-height: 30px;
                font-size: 24px;
                color: $color-text-sub;
              }
              .item-desc_tag {
                display: inline-block;
                font-size: 24px;
                padding: 4px 8px;
                color: $color-danger;
                border: 1px solid $color-danger;
                border-radius: 6px;
                transform: scale(0.66);
                transform-origin: center left;
              }
            }
          }
        }
      }
    }
    .cart-promo-box {
      width: 100%;
      margin-top: 16px;
      margin-bottom: 24px;
      background-color: $color-white;
      .single-promo-box {
        display: flex;
        width: 100%;
        padding: 24px;
        align-items: center;
        flex-wrap: nowrap;
        .promo-name {
          flex: 1;
          font-size: 26px;
          color: $color-text-normal;
        }
        .promo-btn {
          flex: 0;
          margin-left: 16px;
          .btn-item {
            display: inline-block;
            min-width: 120px;
            height: 44px;
            line-height: 42px;
            text-align: center;
            font-size: 24px;
            white-space: nowrap;
            color: $color-primary;
            border: 1px solid $color-primary;
            border-radius: 44px;
          }
        }
      }
    }
  }
}
.detail-selected {
  position: relative;
  width: 100%;
  margin-top: 8px;
  font-size: 24px;
  &.active {
    .detail-selected-box {
      visibility: hidden;
    }
    .detail-selected-box_fixed {
      display: block;
      max-height: 500px;
      opacity: 1;
    }
  }
  .detail-selected-box {
    position: relative;
    width: 100%;
    .detail-selected-content {
      position: relative;
      display: flex;
      width: 100%;
      line-height: 30px;
      padding: 16px 42px 16px 12px;
      background-color: #f3f6fa;
      .result-content {
        height: 30px;
        line-height: 30px;
        overflow: hidden;
      }
    }
    .collapse-btn {
      position: absolute;
      top: 16px;
      right: 30px;
      z-index: 11;
    }
  }
  .detail-selected-box_fixed {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    z-index: 10;
    opacity: 0;
    transition: all 0.5s;
    z-index: 20;
    .detail-selected-content {
      position: relative;
      display: flex;
      width: 100%;
      padding: 16px 12px 48px 12px;
      line-height: 36px;
      background-color: #f3f6fa;
      .collapse-btn {
        position: absolute;
        bottom: 10px;
        right: 30px;
        z-index: 21;
      }
    }
  }
  .result-content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .result-item {
      flex: 0 0 auto;
      padding: 0 12px;
      margin-bottom: 8px;
      white-space: nowrap;
    }
  }
}
</style>
