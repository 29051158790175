<!--
 * @Author: hwu
 * @Date: 2020-11-17 09:43:05
 * @Description: 菜谱页面 -> 购物车底部栏
 * @LastEditTime: 2024-04-11 17:42:38
-->

<template>
  <div class="footer-wrapper">
    <div class="footer-box">
      <div class="footer-cart-box" @click="showDetail()">
        <i class="cart-icon iconfont icongouwuche-man active" v-if="cartQty > 0"></i>
        <i class="cart-icon iconfont icongouwuche-kong" v-else></i>
        <span class="cart-tag" v-show="cartQty > 0">{{ cartQty }}</span>
      </div>
      <div class="footer-amount-box">
        <span v-if="cartQty > 0">￥{{ cartAmount }}</span>
      </div>
      <div class="footer-btn-box" @click="linkToPayment">
        <span class="active">
          <!-- <template v-if="kdsModel !== '2' && tableName">{{ tableName }}</template> -->
          <template> 选好了</template>
        </span>
      </div>
    </div>
    <!-- 购物车详情弹窗 -->
    <menu-cart-detail-popup :show="detailPopupShow" @onClose="detailPopupShow = false" />
    <!-- 商品关联关系弹窗 -->
    <menu-relation-dialog :show="relationDialogShow" :items="relationItemList" @onClose="closeRelationDialog" />
    <!-- 基本收费项弹窗 -->
    <menu-base-fee-dialog :show="baseFeeDialogShow" :items="baseFeeList" @onClose="closeBaseFeeDialog" />
    <!-- 赠品选择弹窗 -->
    <menu-cart-free-promo-popup :show="freePromoPopupShow" @onClose="freePromoPopupShow = false" @onSubmit="submitFreePromo()" />
    <dialog-box :show="orderSuccessDialogShow" title="下单成功" rightBtnText="确定" @onRightClick="linkToOrderGuide">
      <div slot="body">
        <p class="text-left">{{ cartItemDesc }}</p>
        <p class="text-left">订单金额：￥{{ cartAmount }}</p>
      </div>
    </dialog-box>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MenuCartDetailPopup from './MenuCartDetailPopup'
import MenuRelationDialog from './MenuRelationDialog'
import MenuBaseFeeDialog from './MenuBaseFeeDialog'
import MenuCartFreePromoPopup from './MenuCartFreePromoPopup'
import DialogBox from '@/components/common/DialogBox'
import { createOrderApi, getRelationItemApi, getBaseFeeApi } from '@/api/cart'
import { saveOrderCreditApi } from '@/api/order'
export default {
  name: 'menu-cart-footer',
  props: {
    tableName: { type: String, required: false, default: '' }
  },
  components: { MenuCartDetailPopup, MenuRelationDialog, MenuBaseFeeDialog, MenuCartFreePromoPopup, DialogBox },
  data() {
    return {
      detailPopupShow: false,
      relationDialogShow: false,
      relationItemList: [],
      baseFeeDialogShow: false,
      baseFeeList: [],
      freePromoPopupShow: false,
      orderSuccessDialogShow: false
    }
  },
  computed: {
    ...mapState('order', ['cartId', 'cartItems', 'cartQty', 'cartAmount', 'entranceType', 'freePromos', 'userId', 'storeId', 'kdsModel', 'payModel', 'isPad']),
    cartItemDesc() {
      if (!this.cartItems || this.cartItems.length === 0) {
        return '未选择商品'
      }
      const itemList = this.cartItems.reduce((result, item) => {
        return result.concat(item.items)
      }, [])
      const unitItemCount = itemList
        .filter((x) => x.unitType === 1)
        .reduce((result, item) => {
          return result + item.quantity
        }, 0)
      const singleItemCount = itemList
        .filter((x) => x.unitType !== 1)
        .reduce((result, item) => {
          return result + item.quantity
        }, 0)
      return `已下单：${unitItemCount}个套餐、${singleItemCount}个单品；`
    }
  },
  methods: {
    showDetail() {
      // pad是左右横屏，不需要弹出详情弹窗
      if (this.isPad) {
        return false
      }
      if (!this.cartQty) {
        return false
      }
      this.detailPopupShow = !this.detailPopupShow
    },
    linkToPayment() {
      this.$toast.loading({
        duration: 0,
        message: '加载中',
        forbidClick: true
      })

      // 判断赠品是否选择
      this.checkFreePromoSelected()
    },
    checkFreePromoSelected() {
      const existNotSelectedPromo = this.freePromos.some((x) => x.selected !== 1)
      // 如果有未选择的赠品，就弹窗让选择
      if (existNotSelectedPromo) {
        this.$toast.clear()
        this.detailPopupShow = false
        this.freePromoPopupShow = true
        return false
      }
      // 查看是否有关联关系商品
      this.getRelationItem()
    },
    submitFreePromo() {
      this.freePromoPopupShow = false
      this.checkFreePromoSelected()
    },
    getRelationItem() {
      getRelationItemApi(this.cartId, this.userId).then((res) => {
        // 如果有关联商品，则弹出弹窗
        if (res.data && res.data.length > 0) {
          this.$toast.clear()
          this.relationItemList = res.data
          this.relationDialogShow = true
          return false
        }
        // 没有则继续查看是否有基本收费项
        // this.getBaseFee()
        this.createOrder()
      })
    },
    closeRelationDialog() {
      this.relationDialogShow = false
      // this.getBaseFee()
      this.createOrder()
    },
    getBaseFee() {
      getBaseFeeApi(this.cartId).then((res) => {
        // 如果有基本收费项，则弹出弹窗
        if (res.data && res.data.length > 0) {
          this.$toast.clear()
          this.baseFeeDialogShow = true
          this.baseFeeList = res.data
          return false
        }
        // 没有则生成订单
        this.createOrder()
      })
    },
    closeBaseFeeDialog() {
      this.baseFeeDialogShow = false
      this.createOrder()
    },
    createOrder() {
      // 仅挂账需要加购商品才能下单
      if (this.payModel === 3 && (!this.cartItems || this.cartItems.length === 0)) {
        this.$toast('请先加购商品')
        return false
      }
      createOrderApi(this.cartId, this.userId).then((res) => {
        this.$toast.clear()
        if (res.status !== 0) {
          this.$toast(res.msg)
          return
        }
        const orderId = res.data
        const userId = this.userId
        // 如果是仅挂账模式，则不需要跳到支付页，直接下单
        if (this.payModel === 3) {
          saveOrderCreditApi(orderId, userId).then((r1) => {
            if (res.status !== 0) {
              this.$toast(res.msg)
              return
            }
            this.orderSuccessDialogShow = true
          })
          return
        }
        this.$router.push({
          name: 'OrderPayment',
          query: { orderId }
        })
      })
    },
    linkToOrderGuide() {
      this.$router.push({
        name: 'OrderGuide',
        query: {
          storeId: this.storeId,
          userId: this.userId,
          entranceType: this.entranceType,
          isPad: this.isPad ? 1 : 0
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.footer-box {
  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
  width: 750px;
  height: 100px;
  padding: 0 24px;
  align-items: center;
  background-color: $color-white;
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.1);
  z-index: 101;
  .footer-cart-box {
    position: relative;
    flex: 0;
    margin-right: 36px;
    .cart-icon {
      color: $color-text-disabled;
      font-size: 64px;
      &.active {
        color: $color-primary;
      }
    }
    .cart-tag {
      position: absolute;
      display: inline-block;
      top: -10px;
      right: -30px;
      min-width: 44px;
      height: 44px;
      line-height: 44px;
      font-size: 24px;
      text-align: center;
      color: $color-white;
      background-color: $color-danger;
      border-radius: 50%;
      transform: scale(0.58);
      transform-origin: center left;
    }
  }
  .footer-amount-box {
    flex: 1;
    color: $color-text-main;
    font-size: 36px;
    font-weight: bold;
  }
  .footer-btn-box {
    flex: 0;
    span {
      display: inline-block;
      width: 210px;
      height: 72px;
      line-height: 72px;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      color: $color-white;
      background-color: $color-text-disabled;
      border-radius: 8px;
      white-space: nowrap;
      &.active {
        color: $color-white;
        background-color: $color-primary;
      }
    }
  }
}
</style>
