<!--
 * @Author: hwu
 * @Date: 2020-11-18 15:44:38
 * @Description: 菜谱页面 -> 基本收费项弹窗
 * @LastEditTime: 2024-03-28 21:31:22
-->
<template>
  <div class="dialog-wrapper">
    <dialog-box title="基本收费项" :show="show" :showLeftBtn="true" leftBtnText="已经买过" rightBtnText="选好了" @onLeftClick="close" @onRightClick="submit">
      <div slot="body">
        <div class="fee-list">
          <div class="single-item-box" v-for="(item, itemIndex) in items" :key="itemIndex">
            <div class="single-item-right">
              <div class="item-info">
                <div class="item-info_name">
                  <span>{{ item.itemName }}</span>
                  <span class="warning-tips" v-if="item.isMust">必点商品！请按就餐人数加购</span>
                </div>
                <div class="item-info_price">￥{{ item.salePrice }}</div>
                <div class="item-info_btn">
                  <span class="item-btn minus-btn" v-show="item.quantity > 0" @click="minusItemQty(item)">
                    <i class="iconfont iconjianshao"></i>
                  </span>
                  <span class="item-qty" v-show="item.quantity > 0">{{ item.quantity }}</span>
                  <span class="item-btn add-btn" @click="addItemQty(item)">
                    <i class="iconfont iconzengjia-tianchong"></i>
                  </span>
                </div>
              </div>
              <div class="item-desc">
                <span class="item-desc_text">{{ item.description }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dialog-box>
  </div>
</template>
<script>
import DialogBox from '@/components/common/DialogBox'
import { mapActions } from 'vuex'
export default {
  name: 'menu-base-fee-dialog',
  components: { DialogBox },
  props: {
    show: { type: Boolean, required: true, default: false },
    items: { type: Array, required: true, default: () => [] }
  },
  data() {
    return {
      submitDisabled: false
    }
  },
  methods: {
    ...mapActions('order', ['addItemQtyVuex']),
    minusItemQty(item) {
      if (!item.quantity) {
        item.quantity = 1
      }
      item.quantity -= 1
    },
    addItemQty(item) {
      if (!item.quantity) {
        item.quantity = 0
      }
      item.quantity += 1
    },
    close(e) {
      this.$emit('onClose', e)
    },
    submit(e) {
      if (this.submitDisabled) {
        return false
      }
      const itemData = this.items
        .filter((x) => x.quantity > 0)
        .map((y) => {
          return {
            itemId: y.itemId,
            make: y.make,
            taste: y.taste,
            quantity: y.quantity
          }
        })
      const formData = {
        parentId: this.items[0].parentId,
        items: itemData
      }
      this.submitDisabled = true
      this.addItemQtyVuex(formData).then((res) => {
        if (res) {
          this.$emit('onClose', e)
        }
        this.submitDisabled = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .wh-dialog-wrapper {
  .wh-dialog-body {
    padding: 24px 0;
  }
}
.fee-list {
  width: 100%;
  .single-item-box {
    display: flex;
    width: 100%;
    padding: 24px;
    align-items: center;
    text-align: left;
    .single-item-left {
      flex: 0 0 56px;
      margin-right: 16px;
      font-size: 0;
      img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
      }
    }
    .single-item-right {
      flex: 1;
      .item-info {
        display: flex;
        align-items: center;
        line-height: 36px;
        .item-info_name {
          position: relative;
          flex: 1;
          font-size: 28px;
          color: $color-text-main;
        }
        .item-info_price {
          flex: 0;
          margin-right: 56px;
          font-size: 28px;
          color: $color-text-main;
        }
        .item-info_btn {
          flex: 0 0 130px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .item-btn {
            font-size: 0;
            i {
              color: $color-primary;
              font-size: 40px;
            }
            &.disabled {
              opacity: 0.5;
            }
          }
          .item-qty {
            display: inline-block;
            min-width: 40px;
            font-size: 26px;
            font-weight: bold;
            text-align: center;
          }
        }
      }
      .item-desc {
        width: 100%;
        margin-top: 8px;
        .item-desc_text {
          margin-right: 10px;
          line-height: 30px;
          font-size: 24px;
          color: $color-text-sub;
        }
        .item-desc_tag {
          display: inline-block;
          font-size: 24px;
          padding: 4px 8px;
          color: $color-danger;
          border: 1px solid $color-danger;
          border-radius: 6px;
          transform: scale(0.66);
          transform-origin: center left;
        }
      }
    }
  }
}
.warning-tips {
  position: absolute;
  top: -50%;
  right: 0;
  width: 240px;
  padding: 6px 10px;
  font-size: 24px;
  text-align: center;
  color: $color-white;
  background-color: $color-warning;
  opacity: 0.8;
  border-radius: 8px;
  z-index: 11;
  transform: scale(0.8);
  transform-origin: center left;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -2px;
    width: 0;
    height: 0;
    margin-top: -7px;
    border-bottom: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid rgba($color-warning, 0.9);
    transform: rotate(315deg);
    z-index: 11;
  }
}
</style>
