<!--
 * @Author: hwu
 * @Date: 2021-02-02 10:14:40
 * @Description: 菜谱页面 -> 添加购物车 -> 小球动画
 * @LastEditTime: 2021-02-08 14:23:43
-->
<template>
  <div class="ball-container">
    <transition v-for="(ball,index) in balls" :key="index" name="drop" @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter">
      <div class="ball" v-show="ball.show">
        <div class="inner inner-hook"></div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  data () {
    return {}
  },
  computed: {
    ...mapState('order', ['balls', 'dropBalls'])
  },
  created () {
    this.initBallsVuex()
  },
  methods: {
    ...mapActions('order', ['initBallsVuex', 'toggleShowBallVuex', 'shiftDropBallVuex']),
    beforeEnter (el) {
      let count = this.balls.length
      while (count--) {
        let ball = this.balls[count]
        if (ball.show) {
          // getBoundingClientRect返回值是一个 DOMRect 对象, 此包含了一组用于描述边框的只读属性——left、top、right和bottom，单位为像素。
          // 除了 width 和 height 外的属性都是相对于视口的左上角位置而言的。
          let rect = ball.el.getBoundingClientRect()
          let x = rect.left - 32
          let y = -(window.innerHeight - rect.top - 56)
          // 小球外层控制y轴的运动轨迹,translate3d()可以开启硬件加速
          el.style.display = ''
          el.style.webkitTransform = `translate3d(0, ${y}px, 0)`
          el.style.transform = `translate3d(0, ${y}px, 0)`
          // 内层小球控制x轴运动轨迹,内外层运动方式是不一样的, y轴贝塞尔曲线, x轴匀速.
          let inner = el.getElementsByClassName('inner-hook')[0]
          inner.style.webkitTransform = `translate3d(${x}px, 0, 0)`
          inner.style.transform = `translate3d(${x}px, 0, 0)`
        }
      }
    },
    enter (el) {
      // 触发浏览器重绘
      this.$nextTick(() => {
        el.style.webkitTransform = 'translate3d(0, 0, 0)'
        el.style.transform = 'translate3d(0, 0, 0)'
        let inner = el.getElementsByClassName('inner-hook')[0]
        inner.style.webkitTransform = `translate3d(0, 0, 0)`
        inner.style.transform = `translate3d(0, 0, 0)`
      })
    },
    afterEnter (el) {
      let ball = this.dropBalls.shift()
      if (ball) {
        ball.show = false
        el.style.display = 'none'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ball-container {
  .ball {
    position: fixed;
    left: 70px;
    bottom: 80px;
    z-index: 200;
    width: 24px ;
    height: 24px ;
    border-radius: 50%;
    &.drop-enter-active {
      //   transition: all 0.5s cubic-bezier(0.4, -0.49, 0.75, 0.41);
      transition: all 0.5s cubic-bezier(0.39, -0.4, 0.83, 0.23) 0s;
    }
    .inner {
      width: 24px ;
      height: 24px ;
      border-radius: 50%;
      background: $color-danger;
      transition: all 0.5s linear;
    }
  }
}
</style>
