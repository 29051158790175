<!--
 * @Author: hwu
 * @Date: 2020-09-03 09:48:37
 * @Description: 菜谱页面 -> 活动详情弹窗
 * @LastEditTime: 2024-05-14 15:04:43
-->

<template>
  <div class="wh-popup-wraper" @touchmove.stop>
    <transition name="wh-popup">
      <div class="wh-popup-box" :style="{ height: '80%' }" v-if="show">
        <div class="popup-content">
          <div class="brand-box">
            <div class="brand-logo">
              <img :src="transferItemImage(storeInfo.brandLogo)" />
            </div>
            <div class="brand-name">{{storeInfo.brandName}}({{storeInfo.storeName}})</div>
          </div>
          <div class="promo-box">
            <section class="promo-title">
              <img class="promo-title-icon" :src="require('@/assets/images/gift.png')" />
              <span>堂食优惠</span>
              <span class="promo-title_desc">（所有优惠不支持折上折）</span>
            </section>
            <div class="promo-item" v-for="(promo, index) in promos" :key="index">
              <div class="promo-item_name">
                <span class="promo-item_tag">{{promo.promoTag}}</span>
                <span>{{promo.promoName}}</span>
              </div>
              <ul class="promo-item_detail">
                <li v-show="promo.perDayTimes">· 每人每天最多可参与{{ promo.perDayTimes }}次；</li>
                <li>· {{promo | promoTimeFilter}}</li>
              </ul>
            </div>
          </div>
          <div v-if="storeInfo.tips" class="promo-box">

            <section class="promo-title">
              <img class="promo-title-icon" :src="require('@/assets/images/smile.png')" />
              <span>温馨提示</span>
            </section>
            <div class="promo-item">
              <div class="promo-item_detail promo-item_detail-preline">
                {{ storeInfo.tips }}
              </div>
            </div>
          </div>
        </div>
        <div class="close-btn" @click="onClose">
          <i class="iconfont iconshouqi-daanniu"></i>
        </div>
      </div>
    </transition>
    <transition name="wh-backdrop">
      <div class="wh-popup-backdrop" @click="onBackdropClick" v-if="show"></div>
    </transition>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { getStoreInfoApi } from '@/api/store'
export default {
  name: 'menu-promo-popup',
  props: {
    show: { type: Boolean, required: true, default: false },
    promos: { type: Array, required: true, default: () => [] }
  },
  filters: {
    promoTimeFilter (val) {
      const beginDate = moment(val.beginDate).format('YYYY年MM月DD日')
      const endDate = moment(val.endDate).format('YYYY年MM月DD日')
      // return `活动时间：${beginDate}-${endDate} ${val.fromTime}-${val.toTime}；`
      return `活动时间：${beginDate}-${endDate}；`
    }
  },
  computed: {
    ...mapState('order', ['storeId'])
  },
  data () {
    return {
      storeInfo: {}
    }
  },
  created () {
    this.getStoreInfo()
  },
  methods: {
    getStoreInfo () {
      getStoreInfoApi(this.storeId).then((res) => {
        if (res.status !== 0 || !res.data) {
          return false
        }
        this.storeInfo = res.data
      })
    },
    transferItemImage (key) {
      return process.env.VUE_APP_QNY_LOAD_URL + key + '?imageView2/0/w/600'
    },
    onClose (e) {
      this.$emit('onClose', e)
    },
    onBackdropClick (e) {
      this.$emit('onClose', e)
    }
  }
}
</script>
<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 750px;
  height: 100%;
  background-color: $color-white;
  z-index: 2001;
  transform: translate(0,0);
  // -webkit-overflow-scrolling: touch;
  .popup-content {
    padding-bottom: 40px;
    height: 100%;
    overflow-y: scroll;
  }
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateY(-100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}

.brand-box {
  display: flex;
  width: 100%;
  padding: 50px 120px 32px;
  align-items: center;
  .brand-logo {
    flex: 0 0 128px;
    margin-right: 32px;
    img {
      width: 100%;
    }
  }
  .brand-name {
    flex: 1;
    font-size: 36px;
    font-weight: bold;
    color: $color-text-main;
  }
}
.promo-box {
  width: 100%;
  padding: 0 24px ;
  .promo-title {
    margin-bottom: 16px;
    font-size: 26px;
    font-weight: bold;
    color: $color-text-main;
    .promo-title_desc {
      font-size: 24px ;
      font-weight: 400;
      color: $color-text-sub;
    }
  }
  .promo-item {
    width: 100%;
    margin-bottom: 24px ;
    .promo-item_name {
      display: flex;
      width: 100%;
      margin-bottom: 8px;
      align-items: center;
      .promo-item_tag {
        flex: 0 0 32px;
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 16px;
        line-height: 32px;
        text-align: center;
        font-size: 24px ;
        color: $color-white;
        background: $color-danger;
        border-radius: 4px ;
        transform: scale(0.8);
        transform-origin: center left;
      }
      // margin-bottom: 28px;
      font-size: 26px;
      font-weight: bold;
      color: $color-text-normal;
    }
    .promo-item_detail {
      width: 100%;
      padding-left: 52px;
      font-size: 24px ;
      transform: scale(0.9);
      transform-origin: center left;
      li {
        width: 100%;
        margin-bottom: 8px;
      }
    }
    .promo-item_detail-preline {
      white-space: pre-line;
    }
  }
}
.close-btn {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  // padding: 24px  0;
  text-align: center;
  .iconfont {
    font-size: 80px;
    color: $color-text-disabled;
  }
}
.promo-title-icon {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  vertical-align: bottom;
}
</style>
