<!--
 * @Author: hwu
 * @Date: 2021-02-05 10:14:57
 * @Description:  菜谱页面 -> 购物车 -> 赠品弹窗
 * @LastEditTime: 2024-04-02 19:29:24
-->
<template>
  <w-popup v-model="popupShow" position="bottom" :get-container="isInPopup ? 'body' : ''" @close="onClose" :style="{ 'max-height': '80%' }">
    <div class="wh-popup-container">
      <div class="wh-popup-body with-footer">
        <div class="promo-box">
          <div class="single-promo" v-for="(promo, index) in promoList" :key="index">
            <div class="promo-title">
              <span>{{ promo.promoName }}</span>
              <span>（{{ promo.freeItems.length }}款任选{{ promo.totalItemCount }}款）</span>
            </div>
            <div class="promo-item" v-for="(item, itemIndex) in promo.freeItems" :key="itemIndex">
              <div class="item-row">
                <div class="item-row_name">{{ item.itemName }}</div>
                <div class="item-row_price">￥{{ item.price }}</div>
                <div class="item-row_btn">
                  <span class="item-btn minus-btn" v-show="item.quantity > 0" @click="minusSubItemQty(item, promo)">
                    <i class="iconfont iconjianshao"></i>
                  </span>
                  <span class="item-qty" v-show="item.quantity > 0">{{ item.quantity }}</span>
                  <span class="item-btn add-btn" :class="{ disabled: item.disabled }" @click="addSubItemQty(item, promo)">
                    <i class="iconfont iconzengjia-xiankuang"></i>
                  </span>
                </div>
              </div>
              <div class="item-option" v-for="(option, optionIndex) in item.selectedTasteMake" :key="optionIndex">
                <el-select class="item-option-select" v-model="option.taste" size="mini" v-if="item.tastes && item.tastes.length > 0">
                  <el-option class="item-option-select_item" v-for="i in item.tastes" :key="i.name" :label="i.name" :value="i.name"> </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wh-popup-footer">
        <btn-footer rightBtnText="选好了" @onRightClick="onSubmit"></btn-footer>
      </div>
    </div>
  </w-popup>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { Select, Option } from 'element-ui'
import BtnFooter from '@/components/common/BtnFooter'
import { setPromoFreeItemsApi } from '@/api/cart'
export default {
  name: 'menu-cart-free-promo-popup',
  components: { BtnFooter, elSelect: Select, elOption: Option },
  props: {
    show: { type: Boolean, required: true, default: false },
    promos: { type: Array, required: false, defult: () => [] },
    isInPopup: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      popupShow: false,
      promoList: [],
      disabledSubmit: false
    }
  },
  watch: {
    show(val) {
      this.popupShow = val
      if (val) {
        this.promoList = JSON.parse(JSON.stringify(this.freePromos))
        this.refreshGroupStatus()
      }
    }
  },
  computed: {
    ...mapState('order', ['cartId', 'freePromos', 'userId'])
  },
  methods: {
    ...mapActions('order', ['updateCartInfoVuex']),
    minusSubItemQty(item) {
      if (!item.quantity) {
        item.quantity = 1
      }
      item.quantity -= 1
      if (item.tastes && item.tastes.length > 0) {
        item.selectedTasteMake.pop()
      }
      this.refreshGroupStatus()
    },
    addSubItemQty(item) {
      if (item.disabled) {
        return false
      }
      if (!item.quantity) {
        item.quantity = 0
        item.selectedTasteMake = []
      }
      item.quantity += 1
      if (item.tastes && item.tastes.length > 0) {
        const defaultTaste = item.tastes && item.tastes.length > 0 ? item.tastes[0].name : ''
        item.selectedTasteMake.push({
          taste: defaultTaste
        })
      }
      this.refreshGroupStatus()
    },
    refreshGroupStatus() {
      this.promoList.forEach((promo) => {
        let selectedQty = 0
        for (let item of promo.freeItems) {
          selectedQty += item.quantity || 0
        }
        promo.freeItems.forEach((item) => {
          if (selectedQty >= promo.totalItemCount) {
            item.disabled = true
            return true
          }
          if (promo.singleItemCount > 0 && item.quantity > promo.singleItemCount) {
            item.disabled = true
            return true
          }
          item.disabled = false
        })
      })
      this.promoList = JSON.parse(JSON.stringify(this.promoList))
    },
    onClose() {
      this.$emit('onClose')
    },
    onSubmit() {
      if (this.disabledSubmit) {
        return false
      }

      let disabled = false
      const selectedFreeItems = []
      this.promoList.forEach((promo) => {
        let promoSelected = 0
        promo.freeItems.forEach((item) => {
          promoSelected += item.quantity || 0
          if (!item.quantity || item.quantity === 0) {
            return true
          }
          if (!item.selectedTasteMake || item.selectedTasteMake.length === 0) {
            selectedFreeItems.push({
              promoId: promo.promoId,
              itemId: item.itemId,
              productionModelId: item.productionModelId,
              name: item.name,
              quantity: item.quantity
            })
            return true
          }
          item.selectedTasteMake.forEach((option) => {
            selectedFreeItems.push({
              promoId: promo.promoId,
              itemId: item.itemId,
              productionModelId: item.productionModelId,
              name: item.name,
              quantity: 1,
              taste: option.taste
            })
          })
        })
        // 如果不是固定分类，并且选的分数不满足要求，则不能提交
        if (promoSelected < promo.totalItemCount) {
          disabled = true
          return false
        }
      })

      if (disabled) {
        this.$toast('请选择赠品')
        return false
      }
      this.disabledSubmit = true
      setPromoFreeItemsApi(this.cartId, this.userId, selectedFreeItems).then((res) => {
        this.disabledSubmit = false
        if (res.status !== 0) {
          this.$toast(res.msg)
          return false
        }
        const cartInfo = res.data
        this.updateCartInfoVuex(cartInfo)
        this.$emit('onSubmit')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  max-height: 80vh;
  background-color: $color-white;
  border-radius: 32px 32px 0 0;
  z-index: 1001;
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 98;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateY(100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}

.promo-box {
  position: relative;
  width: 100%;
  padding-bottom: 120px;
  background-color: $color-background;
  .single-promo {
    width: 100%;
    padding: 24px;
    background-color: $color-white;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .promo-title {
      position: relative;
      width: 100%;
      padding-left: 12px;
      margin-bottom: 40px;
      line-height: 32px;
      font-size: 26px;
      font-weight: bold;
      color: $color-warning;
      &::before {
        position: absolute;
        left: 0;
        top: 4px;
        content: '';
        width: 4px;
        height: 24px;
        background: $color-warning;
        border-radius: 2px;
      }
    }
    .promo-item {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      .item-row {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: nowrap;
        .item-row_name {
          flex: 1;
          color: $color-text-main;
          font-size: 28px;
        }
        .item-row_price {
          flex: 0;
          padding: 0 60px;
          color: $color-text-main;
          font-size: 28px;
        }
        .item-row_qty {
          flex: 0;
          min-width: 122px;
          text-align: right;
        }
        .item-row_btn {
          flex: 0;
          display: flex;
          min-width: 122px;
          align-items: center;
          justify-content: flex-end;
          .item-btn {
            font-size: 0;
            i {
              color: $color-primary;
              font-size: 40px;
            }
            &.disabled {
              opacity: 0.5;
            }
          }
          .item-qty {
            display: inline-block;
            min-width: 40px;
            font-size: 26px;
            font-weight: bold;
            text-align: center;
          }
        }
      }
      .item-option {
        width: 100%;
        margin-top: 16px;
        .item-option-select:not(:last-child) {
          margin-right: 32px;
        }
      }
    }
  }
}

/deep/ .el-select {
  width: 200px;
  .el-input {
    .el-input__inner {
      height: 52px !important;
      line-height: 52px !important;
      padding: 0 40px 0 10px;
      font-size: 24px;
      color: $color-primary;
      border-color: $color-primary;
      &:focus {
        border-color: $color-primary;
      }
    }
    &.is-focus .el-input__inner {
      border-color: $color-primary;
    }
    .el-input__suffix {
      right: 0;
      .el-input__icon {
        font-size: 26px;
        line-height: 48px !important;
        color: $color-primary;
      }
    }
  }
}
.item-option-select_item {
  font-size: 24px;
  padding: 0 20px;
  // height: 60px;
  // line-height: 60px;
  &.selected {
    color: $color-primary;
  }
}
</style>
