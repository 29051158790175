<!--
 * @Author: hwu
 * @Date: 2020-11-18 15:44:38
 * @Description: 菜谱页面 -> 商品关联关系弹窗
 * @LastEditTime: 2021-02-25 00:05:12
-->
<template>
  <div class="dialog-wrapper">
    <dialog-box title="搭配商品" :show="show" :showLeftBtn="!hasAddItem" leftBtnText="不需要" rightBtnText="选好了" @onLeftClick="close" @onRightClick="close">
      <div slot="body">
        <menu-item-box :item="item" v-for="(item, itemIndex) in itemList" :key="itemIndex" :detailShow="false" @onDetail="showItemDetailPopup(item)"></menu-item-box>
      </div>
    </dialog-box>
    <!-- 商品详情弹窗 -->
    <menu-item-popup :show="itemDetailPopupShow" :item="selectedItem" @onClose="itemDetailPopupShow = false"></menu-item-popup>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import DialogBox from '@/components/common/DialogBox'
import MenuItemBox from './MenuItemBox'
import MenuItemPopup from './MenuItemPopup'
export default {
  name: 'menu-relation-dialog',
  components: { DialogBox, MenuItemBox, MenuItemPopup },
  props: {
    show: { type: Boolean, required: true, default: false },
    items: { type: Array, required: true, default: () => [] }
  },
  data () {
    return {
      itemDetailPopupShow: false,
      selectedItem: {}
    }
  },
  computed: {
    ...mapState('order', ['cartId', 'cartItems']),
    itemList () {
      const list = [...this.items]
      list.forEach((x) => {
        const cartItem = this.cartItems
          .reduce((result, item) => {
            return result.concat(item.items)
          }, [])
          .find((y) => y.itemId === x.itemId)
        if (cartItem) {
          x.quantity = cartItem.quantity
        } else {
          x.quantity = 0
        }
      })
      return list
    },
    hasAddItem () {
      return this.itemList.some((y) => y.quantity > 0)
    }
  },
  methods: {
    showItemDetailPopup (item) {
      this.selectedItem = item
      this.itemDetailPopupShow = true
    },
    close (e) {
      this.$emit('onClose', e)
    },
    submit () {}
  }
}
</script>
<style lang="scss" scoped>
/deep/ .wh-dialog-wrapper {
  .wh-dialog-body {
    padding: 24px  0;
  }
}
</style>
